import React from 'react'
import InGameCharacterShowAndInput from '../components/InGameCharacterShowAndInput'

export default function InGame() {
  return (
    <>
      <div className='in-game-container'>
        <InGameCharacterShowAndInput />
      </div>
    </>
  )
}