import React from 'react';
import TitlePage from './components/TitlePage';
import GameMenu from './components/GameMenu';

function App() {
// Type rfc to create a react component
  return (
    <>
      <TitlePage />
      <GameMenu />
    </>
  );
}

export default App;
